<template>
  <div class="page">
    <Loader v-if="loader"></Loader>
    <div v-if="!loader && uuid" class="container fixed">
      <div class="page__title_main with-margin">Регистрация</div>
      <div class="container__form with-margin">
        <RedactField
          v-for="(field, index) in fields"
          :field="field"
          :error="errors[field.name]"
          v-model="field.value"
          :key="'field_' + index"
        />
      </div>
      <Button :disabled="disabled" @click="submit" text="Отправить"></Button>
    </div>
    <div v-if="!loader && !uuid">Отсканируйте qr еще раз</div>
  </div>
</template>

<script>
import Button from "@/components/common/Button.vue";
import Loader from "@/components/4laps/Loader.vue";
import { notification } from "ant-design-vue";
import { axiosSimpleWrapper } from "../../helpers/dataHelpers";
import RedactField from "@/components/4laps/RedactField.vue";
import json from "@/DUMP/fourLaps/registration.fields.json";
import { parse, parsePhoneNumber } from "libphonenumber-js";
import { logDebug } from "@/helpers/logHelper";

export default {
  name: "Registration",
  components: {
    RedactField,
    Button,
    Loader,
  },
  data() {
    return {
      uuid: "",
      isValid: false,
      loader: true,
      fields: [],
      errors: {},
      disabled: false
    };
  },
  mounted() {
    this.uuid = this.$route.query.uuid;
    this.fields = json;
    logDebug(this.uuid);
    const req = {
      method: "get",
      url: "/pets/" + this.uuid,
    };
    axiosSimpleWrapper(req, (response) => {
      if (response.data.success) {
        this.$router.push({
          path: "/" + this.uuid,
        });
        this.loader = false;
      } else {
        this.loader = false;
      }
    });
  },

  watch: {
    // email(value) {
    //   this.email = value;
    //   this.validateEmail(value);
    // },
    isValid() {
      // this.errors
    },
  },
  methods: {
    // validateEmail(value) {
    //   if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    //     this.isValid = true;
    //   } else {
    //     this.isValid = false;
    //   }
    // },
    // validatePhone(value) {
    //   // eslint-disable-next-line no-useless-escape
    //   const regex = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
    //   if (
    //    regex.test(value)
    //   ) {
    //     this.isValid = true;
    //   } else {
    //     this.isValid = false;
    //   }
    // },
    validate() {
      this.errors = {};
      this.fields.forEach((field) => {
        if (!field.value) {
          field.value = null;
        }
        if (field.required && !field.value) {
          this.errors[field.name] = "Необходимо заполнить данные";
        }
        if (field.value && field.min && field.value.length < field.min) {
          this.errors[
            field.name
          ] = `Минимальное количество знаков: ${field.min}`;
        }
        if (field.value && field.max && field.value.length < field.max) {
          this.errors[
            field.name
          ] = `Максимальное количество знаков: ${field.max}`;
        }
        if (field.value && field.type === "phone") {
          if (field.value.length < 6) {
            this.errors[field.name] = `Слишком короткий номер`;
          } else {
            const phoneNumber = parsePhoneNumber(field.value, "RU");
            if (!phoneNumber.isPossible()) {
              this.errors[field.name] = "Не существующий номер";
            }
          }
        }
      });
      return Object.keys(this.errors).length === 0;
    },
    submit() {
      this.disabled = true;
      if (this.validate()) {
        this.loader = true;
        const data = {};
        this.fields.forEach((item) => {
          if (item.name === "phone") {
            const parsePhone = parse(item.value, "RU");
            if (parsePhone.phone) {
              data[item.name] = parsePhone.phone;
            } else {
              data[item.name] = item.value;
            }
          } else {
            data[item.name] = item.value;
          }
        });
        const req = {
          method: "post",
          url: "/pets/sms/" + this.$route.query.uuid,
          data,
        };
        axiosSimpleWrapper(req, (response) => {
          if (response.data.success && response.data.data) {
            notification.open({
              message: response.data.message,
            });
            this.$router.push({
              path: "/code",
              query: { phone: data.phone, uuid: this.uuid, code: response.data.sms_code},
            });
          } else {
            notification.open({
              message: response.data.message,
            });
          }
        });
        this.loader = false;
        setTimeout(() => {
          this.disabled = false;
        }, 1000)
      } else {
        this.loader = false;
        setTimeout(() => {
          this.disabled = false;
        }, 1000)
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
